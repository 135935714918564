import "./App.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

import { FileUploader } from "react-drag-drop-files";

import { useEffect, useState } from "react";
function App() {
  const [responseConfig, setResponseConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [loginErrorMsg, setLoginErrorMsg] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [customerName, setcustomerName] = useState("");

  const [redirect, setRedirect] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);

  useEffect(() => {
    var url =
      //  "http://localhost:7000/api/token";
      // "https://mgpbifunc-mgpbifunc-test.azurewebsites.net/api/generatetoken?code=5UBV-vu05brXyM1VuHk0hLrRPTZsZRl1l0Vy93XY72BiAzFuzVu_hA==";
      "https://mgpbifunc.azurewebsites.net/api/generatetoken?code=oTvatXhpL7r1Rb-HjG35uXQpQdsOa7DqULKj3hQSdSDOAzFucNuDOg==";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        fileName: fileSelected[0]?.name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // setResponseConfig(response);
        // setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {}, [
    redirect,
    fileSelected,
    isLoggedIn,
    loginErrorMsg,
    username,
    password,
    customerName,
  ]);

  const redirectToReports = () => {
    var url =
      // "http://localhost:8000/api/token";
      // "https://mgpbifunc-mgpbifunc-test.azurewebsites.net/api/generatetoken?code=5UBV-vu05brXyM1VuHk0hLrRPTZsZRl1l0Vy93XY72BiAzFuzVu_hA==";
      "https://mgpbifunc.azurewebsites.net/api/generatetoken?code=oTvatXhpL7r1Rb-HjG35uXQpQdsOa7DqULKj3hQSdSDOAzFucNuDOg==";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        fileName: fileSelected[0]?.name,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setResponseConfig(response);
        setIsLoading(false);
        setRedirect(true);
      })
      .catch((error) => console.log(error));
  };

  const fileUploadChange = (e) => {
    setFileSelected([...fileSelected, e.target.files[0]]);
  };

  const resetDetails = () => {
    setcustomerName("");
    setFileSelected([]);
    setLoginErrorMsg(false);
    setPassword("");
    setUsername("");
  };

  const checkLogin = () => {
    if (
      username.toLowerCase() === "Mode@f3ap!".toLowerCase() &&
      password === "!ModeTe@m"
    ) {
      setIsLoggedIn(true);
    } else {
      setLoginErrorMsg(true);
    }
  };

  return (
    <>
      {/* {fileSelected} */}

      {!isLoggedIn ? (
        <>
          <div className="container-fluid">
            <div className="row body-sty ">
              <div className="col-md-6  container-height">
                <div className="row px-5 pt-5 mt-5 align-item-center align-self-center">
                  <img
                    src="Images/logo-mode.png"
                    className="logo-sty"
                    alt="mode-sty"
                  />
                  <p className="font-36 text-white text-bold">MODE VPO</p>
                  <p className="font-32 text-white mb-0 text-semibold">
                    Be a part of our
                  </p>
                  <p className="font-32 green-clr text-semibold">
                    network of networks
                  </p>
                  <img src="images/background-image.png" alt="" className />
                </div>
              </div>
              <div className="col-md-6 container-height">
                <div className="row pt-5 mt-5 justify-content-center">
                  <div className="col-md-8">
                    <div class="row ">
                      <h5 className="font-32 text-white text-bold ps-0">
                        Login
                      </h5>

                      <label
                        htmlFor="user-Name font-16"
                        className="form-label pb-2 text-white mt-5 text-semibold ps-0"
                      >
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control input-field"
                        id="user-Name"
                        placeholder="Enter Username"
                        value={username}
                        onChange={(e) => {
                          setLoginErrorMsg(false);
                          setUsername(e.target.value);
                        }}
                      />
                      <label
                        htmlFor="pwd-Name font-16"
                        className="form-label pb-2 text-white mt-5 text-semibold ps-0"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control input-field"
                        id="pwd-Name"
                        placeholder="Enter Password "
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setLoginErrorMsg(false);
                        }}
                      />
                      <span
                        hidden={!loginErrorMsg}
                        className="pb-2 text-semibold mt-2 text-danger me-5 pe-5 ps-0"
                      >
                        Invalid Username and Password
                      </span>
                      <div className="d-block text-end my-5">
                        <button
                          className="btn font-16 me-2 secondary-btn"
                          onClick={() => resetDetails()}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn font-16 primary-btn"
                          onClick={() => checkLogin()}
                        >
                          Login
                        </button>
                      </div>
                      <div className="margin-lower">
                        <div class="d-block text-end mt-2 mb-5">
                          <span class="pe-4">
                            <img
                              src="Images/1.Avenger.svg"
                              alt="avenger-logo"
                              class="partner-logo-sty"
                            />
                          </span>
                          <span class="pe-4">
                            <img
                              src="Images/2.Mode.svg"
                              alt="mode-logo"
                              class="partner-logo-sty"
                            />
                          </span>
                          <span>
                            <img
                              src="Images/3.Sunteck.svg"
                              alt="sunteck-logo"
                              class="partner-logo-sty"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="footer fixed-bottom">
                <p className="mt-auto text-white text-end font-12">
                  Copyright © MODE Global {new Date().getFullYear()}
                </p>
              </footer>
            </div>
          </div>
        </>
      ) : isLoading || !redirect ? (
        <>
          <div className="container-fluid">
            <div className="row body-sty ">
              <div className="col-md-6  container-height">
                <div className="row px-5 pt-5 mt-5 align-item-center align-self-center">
                  <img
                    src="Images/logo-mode.png"
                    className="logo-sty"
                    alt="mode-sty"
                  />
                  <p className="font-36 text-white text-bold"> MODE VPO</p>
                  <p className="font-32 text-white mb-0 text-semibold">
                    Be a part of our
                  </p>
                  <p className="font-32 green-clr text-semibold">
                    network of networks
                  </p>
                  <img src="images/background-image.png" alt="" className />
                </div>
              </div>
              <div className="col-md-6 container-height">
                <div className="row pt-5 mt-5 justify-content-center">
                  <div className="col-md-8">
                    <h5 className="font-32 text-white text-bold">
                      Customer Details
                    </h5>
                    <label
                      htmlFor="Customer-Name font-16"
                      className="form-label pb-2 text-white mt-5 text-semibold"
                    >
                      Customer Name
                    </label>
                    <input
                      type="text"
                      className="form-control input-field"
                      id="Customer-Name"
                      placeholder="Enter customer name"
                      value={customerName}
                      onChange={(e) => setcustomerName(e.target.value)}
                    />
                    <div className="d-flex justify-content-between">
                      <label
                        htmlFor="Customer-Name font-16"
                        className="form-label pb-2 text-white mt-5 text-semibold"
                      >
                        Upload File <span className="red-clr">*</span>
                      </label>
                      <a
                        href="Template/Download Template.xlsx"
                        className="font-16 text-end download-sty pb-2 mt-5 text-semibold"
                        hidden={fileSelected.length > 0}
                        download
                      >
                        Download Template
                      </a>
                    </div>

                    <div
                      class="d-flex mt-3 justify-content-between"
                      hidden={!(fileSelected.length > 0)}
                    >
                      <p class="text-white text-semibold font-18">
                        <span hidden={!(fileSelected.length > 0)}>
                          <img
                            src="Images/Excel_icon.svg"
                            alt="excel-icon"
                            class="excel-icon-sty me-3"
                          />
                        </span>
                        {fileSelected[0]?.name}
                        <span
                          class="text-bold ms-3 font-13 unsaved-status-sty"
                          hidden={!(fileSelected.length > 0)}
                        >
                          Saved
                        </span>
                      </p>
                      <div class="d-block">
                        <img
                          src="Images/close-icon.svg"
                          alt="close-icon"
                          class="close-icon-sty"
                          hidden={!(fileSelected.length > 0)}
                          onClick={() => {
                            setFileSelected([]);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="upload-btn-wrapper d-block"
                      hidden={fileSelected.length > 0}
                    >
                      <button
                        className="btn upload-btn-sty py-4"
                        hidden={fileSelected.length > 0}
                      >
                        <span className="align-item-center d-block">
                          <img
                            src="Images/upload-icon.png"
                            alt="upload-icon"
                            className="upload-icon-sty mb-2"
                          />
                        </span>
                        Click to upload or drag and drop
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        multiple
                        onChange={(e) => fileUploadChange(e)}
                        hidden={fileSelected.length > 0}
                      />
                    </div>
                    <div className="d-block  text-end my-5">
                      <button
                        type="button"
                        className="btn font-16 me-2 secondary-btn"
                        onClick={() => resetDetails()}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        className="btn font-16 primary-btn"
                        onClick={() => redirectToReports()}
                      >
                        Upload
                      </button>
                    </div>
                    <div className="margin-lower">
                      <div class="d-block text-end mt-2 mb-5">
                        <span class="pe-4">
                          <img
                            src="Images/1.Avenger.svg"
                            alt="avenger-logo"
                            class="partner-logo-sty"
                          />
                        </span>
                        <span class="pe-4">
                          <img
                            src="Images/2.Mode.svg"
                            alt="mode-logo"
                            class="partner-logo-sty"
                          />
                        </span>
                        <span>
                          <img
                            src="Images/3.Sunteck.svg"
                            alt="sunteck-logo"
                            class="partner-logo-sty"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="footer fixed-bottom">
                <p className="mt-auto text-white text-end font-12">
                  Copyright © MODE Global {new Date().getFullYear()}
                </p>
              </footer>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="APP">
            <PowerBIEmbed
              embedConfig={{
                type: responseConfig.type, // Supported types: report, dashboard, tile, visual, and qna.
                id: responseConfig.reportId,
                embedUrl: responseConfig.embedURL,
                accessToken: responseConfig.embedToken,
                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                    pageNavigation: {
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Default,
                },
              }}
              cssClassName={"report-style-class"}
            />
          </div>
          {() => {
            var x = document.getElementById("myframe");
            var y = x.contentWindow || x.contentDocument;
            if (y.document) y = y.document;
            y.body.style.backgroundColor = "red";
            document.getElementsByClassName(
              "displayAreaContainer"
            )[0].style.background = "black";
          }}
        </>
      )}
    </>
  );
}

export default App;
